import { Box } from '@mui/material';
import Header from 'src/components/header/header';
import Video from 'src/modules/landingpage/home/components/video/video';
import './default-layout.css';
import { m } from 'framer-motion';

type Props = {
  children: React.ReactNode;
};

export default function DefaultLayout({ children }: Props) {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Video />
      <Box sx={{
        position: 'absolute',
        backgroundColor: '#00000077',
        height: '100%',
        width: '100%',
        zIndex: 1
      }} />
        <Box
          sx={{ zIndex: 5 }}
          pt={2}
        >
          <Header />
        </Box>
      <Box height={1} position='absolute'>
        {children}
      </Box>
    </Box>
  );
}
