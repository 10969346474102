import { useCallback, useState } from 'react';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { m } from 'framer-motion';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from 'src/hooks/use-responsive';

interface ActionsProps {}
interface Params {
  from: number;
  to: number;
}

export default function Actions(Props: ActionsProps) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');
  const valuesFromHover = useCallback(
    ({ from, to }: Params) => {
      if (isHovered) return from;
      return to;
    },
    [isHovered]
  );

  if (isMobile) {
    return (
      <Stack
        sx={{
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <m.span
          whileTap={{
            scale: 0.7,
          }}
        >
          <MailOutlineIcon
            onClick={() => {
              navigate('/contact');
            }}
            sx={{ color: '#fff' }}
          />
        </m.span>
      </Stack>
    );
  }

  return (
    <Stack
      onClick={() => {
        navigate('/contact');
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      direction="row"
      spacing={1}
      sx={{
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <m.span
        animate={{
          x: valuesFromHover({ from: -2, to: 0 }),
        }}
        transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.5,
        }}
      >
        <MailOutlineIcon sx={{ color: '#fff', mt: 1 }} />
      </m.span>

      <m.div
        initial={{ width: 0, opacity: 0 }}
        animate={{
          width: isHovered ? 'auto' : 0,
          opacity: isHovered ? 1 : 0,
        }}
        transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.4,
        }}
        style={{
          overflow: 'hidden',
          display: 'flex',
          transformOrigin: 'left',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography color="#fff" fontSize={12} fontFamily="Poppins">
          Fale Conosco
        </Typography>
      </m.div>
    </Stack>
  );
}
