export const apiTranslation = {
  errors: {
    Unauthorized: 'Não autorizado',
    'password must have uppercase letters, lowercase letters and numbers':
      'Sua senha precisa ter letras maiúsculas, minúsculas e números',
    'password and confirm password must match': 'Senha e Confirmar Senha precisam ser iguais',
    'password must be longer than or equal to 8 characters':
      'Senha precisa pelo menos 8 caracteres',
    'password must be shorter than or equal to 32 characters':
      'Senha precisa ter no máximo 32 caracteres',
    'title should not be empty': 'Título não pode ser vazio',
    'description should not be empty': 'Descrição não pode ser vazio',
    'kind must contain at least 1 elements': 'Tipo precisa ter pelo menos uma opção selecionada',
    'role should not be empty': 'Função não pode ser vazio',
    'tech_skills must contain at least 1 elements':
      'Competências técnicas precisa ter pelo menos uma opção selecionada',
    'behavior_skills must contain at least 1 elements':
      'Competências comportamentais precisa ter pelo menos uma opção selecionada',
    "Duplicate unique key 'cpf'": 'O CPF informado já está cadastrado para outro candidato',
  },
};
