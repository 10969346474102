import { lazy } from 'react';

import DefaultLayout from 'src/layouts/default/default-layout';

import { paths } from '../paths';

const NotFoundPage = lazy(() =>  import('src/modules/error/not-found/not-found'));
const ForbiddenPage = lazy(() =>  import('src/modules/error/forbidden/forbidden'));
const InternalErrorPage = lazy(() =>  import('src/modules/error/internal-error/internal-error'));

export const errorRoutes = [
  {
    path: paths.error.forbidden,
    element: (
      <DefaultLayout>
        <ForbiddenPage />
      </DefaultLayout>
    )
  },
  {
    path: paths.error.notFound,
    element: (
      <DefaultLayout>
        <NotFoundPage />
      </DefaultLayout>
    )
  },
  {
    path: paths.error.serverError,
    element: (
      <DefaultLayout>
        <InternalErrorPage />
      </DefaultLayout>
    )
  }
];
