import { ErrorDto } from './error-dto';
import { responseMessage } from './message-error';

export class BadRequestError extends Error {
  error: string;

  statusCode: number;

  code?: string;

  constructor(body: ErrorDto) {
    super(responseMessage(body.message));
    this.name = 'BadRequestError';
    this.error = body.error;
    this.message = responseMessage(body.message);
    this.statusCode = body.statusCode;
    this.code = body.code;
  }
}
