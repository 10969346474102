export namespace HttpTypes {
  export type HttpMethod = 'post' | 'get' | 'put' | 'delete' | 'patch';

  export enum HttpStatusCode {
    ok = 200,
    created = 201,
    noContent = 204,
    badRequest = 400,
    unprocessableentity = 422,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    notAcceptable = 406,
    tooManyRequests = 429,
    connection = 0,
    serverError = 500,
  }

  export type HttpRequest = {
    url: string;
    method: HttpMethod;
    body?: any;
    params?: { [key: string]: any }[];
    headers?: any;
    responseType?: any;
  };

  export type HttpResponse = {
    statusCode: HttpStatusCode;
    body?: any;
  };

  export interface HttpClient {
    request: (data: HttpRequest) => Promise<HttpResponse>;
    fakeRequest: (data: HttpRequest, options: RequestOptions) => Promise<HttpResponse>;
  }

  export type RequestOptions = {
    status: number;
    data: any;
    delay?: number;
  };
}
