import {
  makeAboutUsScreen,
  makeCasesScreen,
  makeContactUsScreen,
  makeHomeScreen,
} from '../factory/default';
import { Route } from '../types/use-routes.interface';

export const mainRoutes: Route[] = [
  makeHomeScreen(),
  makeContactUsScreen(),
  makeCasesScreen(),
  makeAboutUsScreen(),
];
