import React, { cloneElement, lazy, Suspense } from 'react';
import { render, RenderResult } from '@testing-library/react';
import { useRoutes, MemoryRouter, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { errorRoutes } from './error';
import { mainRoutes } from './default';
import { othersRoutes } from './others';

export const renderWithRouter = (ui: React.ReactElement): RenderResult =>
  render(<MemoryRouter>{ui}</MemoryRouter>);

export default function Router() {
  const location = useLocation();
  const element = useRoutes([...othersRoutes, ...errorRoutes, ...mainRoutes]);
  if (!element) return null;
  return (
    <AnimatePresence mode="wait">
      {cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}
