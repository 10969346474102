import { responseMessage } from './message-error';

type BodyError = {
  code?: string;
  message?: string;
};
export class AccessDeniedError extends Error {
  code?: string;

  constructor(body?: BodyError) {
    super(
      (body?.message && responseMessage(body?.message)) ?? 'Você não tem permissão para continuar.'
    );
    this.name = 'AccessDeniedError';
    this.code = body?.code;
  }
}
