export const feedbackTranslation = {
  form_error: 'Ocorreu um erro no envio do formulário.',
  load_error: 'Ocorreu um erro para carregar {{resource}}',
  user_registered: 'Usuário registrado com sucesso!',
  user_logged: 'Log In realizado com sucesso!',
  company_created: 'Empresa criada com sucesso!',
  job_created: 'Vaga criada com sucesso!',
  candidate_created: 'Candidato criado com sucesso!',
  candidate_edited: 'Candidato editado com sucesso!',
};
