import { Either } from 'src/utils/either';

import { httpResponseHandler } from 'src/http/http-response-handler';

import {
  HttpTypes,
  BadRequestError,
  ConnectionError,
  UnexpectedError,
  AccessDeniedError,
  InternalServerError,
  TooManyRequestsError,
} from '../../http';

export class ContactRepository {
  constructor(private api: HttpTypes.HttpClient) {}

  async sendContact(
    body?: any
  ): Promise<
    Either<
      | BadRequestError
      | UnexpectedError
      | InternalServerError
      | AccessDeniedError
      | ConnectionError
      | TooManyRequestsError,
      any | undefined
    >
  > {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `contact/slack`,
      body,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }
}
