import { useGlobal } from 'src/stores/landingpage/global.store';
import { Box } from '@mui/material';
import { useVideo } from './video.hook';

export interface VideoProps {}

export default function Video(Props: VideoProps) {
  const { isFirstVideo } = useGlobal((e) => e);
  const { videoURL, loopVideoUrl, handleVideoEnd } = useVideo();

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 0,
      }}
    >
      <Box
        disablePictureInPicture
        controls={false}
        component="video"
        playsInline
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        src={isFirstVideo ? videoURL : loopVideoUrl}
        autoPlay
        muted
        loop
        onContextMenu={(e) => e.preventDefault()}
        onEnded={handleVideoEnd}
      />
    </Box>
  );
}
