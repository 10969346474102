import { create } from 'zustand';
import { AxiosHttpClient } from 'src/http';
import { ContactRepository } from 'src/repositories/landingpage/contact.repository';

type States = {
  isLoading: boolean;
  anyValue: any;
  isFirstVideo: boolean;
};

type Actions = {
  setAnyValue: (value: any) => void;
  setIsFirstVideo: (value: boolean) => void;
  sendContact: (params: any) => Promise<boolean>;
};

type Global = Actions & States;

const initialValues = {
  isLoading: false,
  anyValue: 'any value',
  isFirstVideo: true,
};

const makeApi = () => new AxiosHttpClient('https://backend.roxcode.io');

const repository = new ContactRepository(makeApi());


export const useGlobal = create<Global>((set: any, get: any) => ({
  ...initialValues,

  setAnyValue(value: any){
    set({ anyValue: value })
  },

  setIsFirstVideo(value: boolean){
    set({ isFirstVideo: value })
  },

  sendContact: async (params: any) => {
    set({ isLoading: true });
    const response = await repository.sendContact(params);
    if (response.isLeft()) {
      set({ isLoading: false });
      console.log('[ERRO]', response.value.message);
      return false;
    }
    console.log('[SUCESSO]', response.value);
    set({ isLoading: false });
    return true;
  },

}));
