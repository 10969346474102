import { ResourceLanguage } from 'i18next';

import { apiTranslation } from './api';
import { authTranslation } from './auth';
import { formTranslation } from './form';
import { termsTranslation } from './terms';
import { feedbackTranslation } from './feedback';

const translation: ResourceLanguage = {
  company: 'Empresa',
  candidate: 'Candidato',
  job: 'Vaga',
  auth: authTranslation,
  form: formTranslation,
  terms: termsTranslation,
  feedback: feedbackTranslation,
  api: apiTranslation,
};

export default translation;
